const awsconfig = {
    Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_nRrTUr2Qa',
        userPoolWebClientId: '43pj9gr7h6ute5ek2nn3n4g6dc',
        mandatorySignIn: false,
    },
    Analytics: {
        disabled: true,
    }
}

export default awsconfig;