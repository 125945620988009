import {Accordion, AccordionDetails, AccordionSummary, List, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import "./css/criteria.css";
import {Config, Row} from "./CriteriaRow";
import {Hour} from "./Hour";
import {useEffect, useState} from "react";

const CRITERIA: Config[]  = [
    {
        key: "minTemperature",
        get: (hour: Hour) => hour.FeelsLikeTemperature,
        default: [2, 15],
        label: 'Temperature',
        start: -10,
        end: 30,
    },
    {
        key: "maxWindSpeed",
        get: (hour: Hour) => hour.Max10mWindGust,
        default: [5, 15],
        label: 'Wind Speed',
        start: 0,
        end: 30,
        reversed: true,
    },
    {
        key: "maxPrecipitation",
        get: (hour: Hour) => hour.TotalPrecipAmount,
        default: [0.5, 8],
        label: 'Rainfall',
        start: 0,
        end: 10,
        step: 0.5,
        reversed: true,
    },
    {
        key: "sunOffset",
        get: (hour: Hour) => hour.Sunlight,
        default: [0.5, 1.5],
        label: 'Sunlight Hours',
        start: 0,
        end: 12,
        step: 0.5,
    },
    {
        key: "minTideHeight",
        get: (hour: Hour) => hour.TideHeight,
        default: [1, 2],
        label: 'Tide Height',
        start: 0,
        end: 16,
        step: 0.5,
        optional: true,
    },
]

const DEFAULT_CRITERIA = Object.fromEntries(CRITERIA.map(x => [x.key, x.default]));


function Criteria(props: {criteria: {[key: string]: number[]}, onUpdate: (update: {[key: string]: number[]}) => void}) {
    const [criteria, setCriteria] = useState(DEFAULT_CRITERIA)

    useEffect(() => {setCriteria(props.criteria)}, [props.criteria])

    function update(key: string, value: number[]) {
        criteria[key] = value
        props.onUpdate(criteria)
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={"criteria-header"}>Criteria</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {
                        CRITERIA.map(row => <Row key={`criteria-${row.key}`} criteria={criteria[row.key]} config={row} onChange={(value) => update(row.key, value) }/> )
                    }
                </List>
            </AccordionDetails>
        </Accordion>
    );
}

export { CRITERIA, Criteria, DEFAULT_CRITERIA };
