import React from 'react';
import {TableCell, TableRow, Typography} from "@mui/material";
import "./css/timeline.css"

export interface Interval {
    index: number,
    key: number,
    date: Date
}

const renderHour = (item: Interval, includeDate: boolean) => {
    return (
        <TableCell className={"timeline-hour"} key={`date-header-${item.index}`} >
            { includeDate ? <Typography className={"date-header"} variant={"h6"}>{item.date.toDateString().slice(0, -5)}</Typography> : <></> }
            <Typography className={"time-header"} variant={"h5"}>{item.date.getHours().toString().padStart(2, '0')}</Typography>
        </TableCell>
    )
};

function Timeline(props: {intervals:Interval[]}) {
    return (
        <TableRow className={"timeline"}>
            <TableCell className={"timeline-hour"}/>
            {
                props.intervals.map((hour, index) => {
                    if (index === 0) {
                        return renderHour(hour, true);
                    }
                    const previousDate = props.intervals[index-1].date;
                    if (previousDate.getDate() !== hour.date.getDate()) {
                        return renderHour(hour, true)
                    }
                    if (previousDate.getHours() === 11 && hour.date.getHours() === 12) {
                        return renderHour(hour, true)
                    }
                    return renderHour(hour, false);
                })
            }
        </TableRow>
    );
}

export default React.memo(Timeline);