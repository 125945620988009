import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import HomeScreen from './HomeScreen'
import {HashRouter, Route, Routes} from "react-router-dom";
import {LocationDetails} from "./LocationDetails";

function App() {

    return (
        <HashRouter>
            <Routes>
                <Route path={"/"} element={<HomeScreen />} />
                <Route path={"/location"} element={<LocationDetails />} />
                <Route path={"/location/:locationId"} element={<LocationDetails />} />
            </Routes>
        </HashRouter>
    );
}

Amplify.configure(awsconfig);

export default withAuthenticator(App);
