import React, {useState} from 'react';
import { Auth } from 'aws-amplify';
import {CircularProgress, TableCell, TableRow, Typography} from "@mui/material";
import {CRITERIA} from "./Criteria";
import {Hour, HourView} from "./Hour";
import {Link} from "react-router-dom";
import "./css/location.css";
import {BASE_URL} from "./config";
import {Interval} from "./Timeline";
import {Config} from "./CriteriaRow";
import {LocationData} from "./LocationDetails";

const createHour = (index: number) => {
    return {
        index: index.toString(),
        background: "",
        border: "",
        FeelsLikeTemperature: 0,
        Max10mWindGust: 0,
        ProbOfPrecipitation: 0,
        Sunlight: 0,
        TideHeight: 0,
        TotalPrecipAmount: 0,
        WindDirectionFrom10m: 0,
    }
}


function Location(props: {intervals: Interval[], location: LocationData}) {
    const [hours, setHours] = useState(props.intervals.map((interval, index) => createHour(index)));
    const [loading, setLoading] = useState(true);

    const fetchLocationData = async (location: LocationData) => {
        const query = `latitude=${location.coordinates.latitude}&longitude=${location.coordinates.longitude}&tideStation=${location.tideStation.id || ''}`
        return fetch(`${BASE_URL}/location?${query}`, {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
        });
    }

    function calculateValue(hour: Hour, criteria: number[] | undefined, config: Config) {
        const value = config.get(hour);
        const criterion = criteria || config.default;
        if (config.optional && !value) {
            return 10;
        }
        if (config.reversed && value > criterion[1]) {
            return 0;
        }
        if (config.reversed && value < criterion[0]) {
            return 10;
        }
        if (!config.reversed && value < criterion[0]) {
            return 0;
        }
        if (!config.reversed && value > criterion[1]) {
            return 10;
        }
        const range = Math.abs(criterion[1] - criterion[0])
        const result = Math.abs(value - criterion[0]) * 10/range;
        return config.reversed ? 10 - result : result;
    }

    const updateBorder = (hour: Hour) => {
        const criteria = props.location.criteria;
        let sum = 0;
        CRITERIA.forEach(config => {
            const value = calculateValue(hour, criteria[config.key], config);
            if (value) {
                sum += value
            } else {
                hour.border = 'red';
            }
        });
        if (hour.border) {
            return;
        }
        if (sum > 25) {
            hour.border = `#${((50-Math.round(sum))*10).toString(16).padStart(2, "0")}FF00`
        } else {
            hour.border = `#FF${((Math.round(sum))*10).toString(16).padStart(2, "0")}00`
        }
    }

    const loadData = (location: LocationData) => {
        fetchLocationData(location)
            .then((response) => response.json())
            .then((locationData) => {
                const intervals = locationData.length
                localStorage.setItem("@location." + location.id, JSON.stringify(locationData));
                const locationHours: Hour[] = [];
                let i = 0;
                props.intervals.forEach(x => {
                    while (i < intervals - 1 && locationData[i].Timestamp < x.key) {
                        i++
                    }
                    const item = locationData[i]
                    if (item) {
                        const darkness = (item.HoursToSunrise > 0 || item.HoursToSunset < 0) ? 255 - (16 * Math.min(Math.abs(item.HoursToSunrise), Math.abs(item.HoursToSunset))) : 255
                        const background = `rgb(${darkness},${darkness},${darkness})`
                        const Sunlight = Math.min(-1*item.HoursToSunrise, item.HoursToSunset)
                        const hour = { ...item, key: x.key, index: x.index, background, Sunlight }
                        updateBorder(hour);
                        locationHours.push(hour);
                    }
                    i++
                })
                setHours(locationHours);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    React.useEffect(() => loadData(props.location), [props.location]);

    return (
        <TableRow>
            <TableCell className={"location-column"}>
                <Link className={"location-link"} to={`location/${props.location.id}`}>
                    <Typography className={"location-header"} variant={"h5"}>{props.location.name}</Typography>
                </Link>
            </TableCell>
            {
                hours.map((hour: Hour) => {
                    return (
                        <TableCell key={`hour-${hour.index}`} className={"location-cell"}>
                            { loading ? <CircularProgress /> : <HourView hour={hour}    /> }
                        </TableCell>
                    )
                })
            }
        </TableRow>
    );
}

export default Location;