import {useEffect, useState} from "react";
import {Direction, getTrackBackground, Range} from "react-range";
import {ListItem, Typography} from "@mui/material";
import {Hour} from "./Hour";

interface Config {
    key: string,
    label: string,
    start: number,
    end: number,
    default: number[],
    get: (hour: Hour) => number,
    step?: number,
    reversed?: boolean,
    optional?: boolean
}

const COLORS = ['red', 'orange', 'green'];

function Row(props: {config: Config, criteria: number[], onChange: (change: number[]) => void}) {
    const [values, setValues] = useState(props.config.default);
    const { start, end, reversed } = props.config;
    const direction = reversed ? Direction.Left : Direction.Right;
    useEffect(() => {
        if (props.criteria) {
            setValues(props.criteria)
        }
    }, [props.criteria])
    return (
        <ListItem className={"criteria"}>
            <Typography className={"criteria-label"}>{props.config.label}</Typography>
            <Range min={start}
                   max={end}
                   step={props.config.step || 1}
                   values={values}
                   direction={direction}
                   renderTrack={({ props, children }) => {
                       return (
                           <div className={"criteria-track"}
                                {...props}
                                style={{
                                    ...props.style,
                                    backgroundImage: getTrackBackground({
                                        min: start,
                                        max: end,
                                        values,
                                        direction,
                                        colors: reversed ? COLORS.reverse() : COLORS
                                    })
                                }}>
                               {children}
                           </div>
                       );
                   }}
                   renderThumb={({ props, value }) => (<div className={"criteria-thumb"} {...props} >{value}</div>)}
                   onChange={setValues}
                   onFinalChange={(value) => {
                       props.onChange(value)
                   }}
            />
        </ListItem>
    );
}

export { Row };
export type { Config };
