import {useEffect, useState} from 'react';
import { Auth } from 'aws-amplify';
import {CircleMarker, MapContainer, Marker, TileLayer, useMapEvents} from "react-leaflet";
import {Criteria, DEFAULT_CRITERIA} from './Criteria';
import {Button, Card, CircularProgress, Modal, TextField, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import './css/locationDetails.css'
import {BASE_URL} from "./config";

const INITIAL_LOCATION = {latitude: 55.0, longitude: -3.5};

interface TideStation {
    Geometry: { Coordinates: number[] },
    Properties: { Name: string, ID: string }
}

interface LocationData {
    id: number,
    name: string,
    tideStation: { id: number },
    criteria: {[key: string]: number[]},
    coordinates: { latitude: number, longitude: number }
}

interface LatLng {
    lat: number,
    lng: number
}

function LocationHandler(props: {position: LatLng, onMove: (position: LatLng) => void}) {
    const [currentPosition, setCurrentPosition] = useState(props.position)
    const map = useMapEvents({
        click(e) {
            setCurrentPosition(e.latlng);
            props.onMove(e.latlng)
        },
        locationfound(e) {
            setCurrentPosition(e.latlng);
            props.onMove(e.latlng)
        },
    });
    const position = props.position || currentPosition;
    map.flyTo(position, 11);
    return position === null ? null : <CircleMarker center={position} />
}

function LocationDetails() {
    const { locationId } = useParams();
    const navigate = useNavigate();
    const [location, setLocation] = useState({
        id: -1,
        name: "",
        coordinates: INITIAL_LOCATION,
        tideStation: { name: "", id: "" },
        criteria: DEFAULT_CRITERIA
    });
    const [markers, setMarkers] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const locations = JSON.parse(localStorage.getItem('@locations') || "[]");
        const current = locations.find((location:LocationData) => location.id.toString() === locationId);
        if (current) {
            setLocation(current)
            fetchMarkers({lat: current.coordinates.latitude, lng: current.coordinates.longitude}).then()
        }
    }, [locationId])

    const updatePosition = async (coordinate: LatLng) => {
        setLocation({...location, coordinates: {latitude: coordinate.lat, longitude: coordinate.lng}})
        await fetchMarkers(coordinate);
    }

    const fetchMarkers = async (coordinate: LatLng) => {
        fetch(`${BASE_URL}/tideStations?latitude=${coordinate.lat.toFixed(0)}&longitude=${coordinate.lng.toFixed(0)}`, {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
        })
            .then(response => response.json())
            .then(stations => setMarkers(stations))
            .catch(console.error);
    }

    const updateLocations = (remove: boolean) => {
        setSaving(true);
        const locations = JSON.parse(localStorage.getItem('@locations') || "[]");
        if (remove) {
            const index = locations.findIndex((l: LocationData) => l.id === location.id)
            locations.splice(index, 1)
        } else if (location.id > 0) {
            Object.assign(locations.find((l: LocationData) => l.id === location.id), location)
        } else {
            locations.push({...location, id: new Date().getTime().toString()})
        }
        localStorage.setItem('@locations', JSON.stringify(locations));
        setSaving(false);
        navigate("/");
    };

    const latLng: LatLng = { lat:location.coordinates.latitude, lng:location.coordinates.longitude };
    return (
        <Card style={styles.container}>
            <Modal open={saving} onClose={() => {}}>
                <Card>
                    <Typography>Updating...</Typography>
                    <CircularProgress />
                </Card>
            </Modal>
            <div className={"details-header"}>
                <div />
                <TextField placeholder='Name' className={"details-name"} value={location.name} onChange={(e) => setLocation({...location, name:e.target.value})} />
                <div>
                    <Button disabled={saving || location.name.length < 1 } variant={"contained"} className={"confirmation"} onClick={() => updateLocations(false)}>
                        {location.id ? "Save" : "Add"}
                    </Button>
                    {location.id > 0 ?
                        <Button disabled={saving} variant={"contained"} className={"confirmation"} onClick={() => updateLocations(true)}>
                            Remove
                        </Button> : <></>
                    }
                </div>
            </div>
            <Typography style={styles.instructions}>Tap a point on the map to select a location and a circle should appear. Tap a pin to use that station for tide information</Typography>
            <MapContainer center={latLng} zoom={5} scrollWheelZoom={true} style={{height: "50vh"}}>
                {
                 //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    eventHandlers={{
                        load: (e) => {
                            const map = e.target._map;
                            if (!locationId && location.coordinates === INITIAL_LOCATION) {
                                map.locate();
                            }
                        }
                    }}
                />
                <LocationHandler position={latLng} onMove={updatePosition}/>
                {
                    markers.map((m:TideStation) =>
                        <Marker key={m.Properties.ID} position={[m.Geometry.Coordinates[1], m.Geometry.Coordinates[0]]} title={ m.Properties.Name } eventHandlers={{
                            click: () => {
                                setLocation({...location, tideStation: {name: m.Properties.Name, id: m.Properties.ID}})
                            },
                        }} />
                    )
                }
            </MapContainer>
            <Typography style={styles.name}>Tide Station: {location.tideStation.name}</Typography>
            <Criteria criteria={location.criteria} onUpdate={(criteria) => setLocation({...location, criteria})}/>
        </Card>
    )
}

const styles = {
    container: {
        flex: 1,
        flexGrow: 1
    },
    name: {
        padding: 15,
        fontSize: '1.5em'
    },
    instructions: {
        padding: 15,
        fontSize: '1.2em'
    },
    confirmText: {
        fontSize: '1.5em',
        color: 'white'
    }

};

export { LocationDetails };
export type { LocationData };