import React from 'react';
import { IconButton, CircularProgress, Table, TableBody, TableHead} from "@mui/material"
import { Add } from "@mui/icons-material"
import Location from './Location'
import Timeline, {Interval} from './Timeline';
import {Link} from "react-router-dom";
import "./css/homeScreen.css";
import {LocationData} from "./LocationDetails";

const createData = () => {
    const data: Interval[] = [];
    const now = Date.now();
    for (let i = 0; i < 81; i++) {
        const hourOffset = i < 48 ? i : (i - 48) * 3 + 48;
        const date = new Date(hourOffset * 3600000 + now);
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)
        const hour = {index: i, key: date.getTime()/1000, date: date}
        data.push(hour);
    }
    return data;
};

const intervals = createData();

function HomeScreen() {
    const [locations, setLocations] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const sortLocations = (locations: any, newLocation: GeolocationPosition) => {
        locations.sort((a: any, b: any) => Math.abs(a.coordinates.latitude - newLocation.coords.latitude) +
            Math.abs(a.coordinates.longitude - newLocation.coords.longitude) -
            Math.abs(b.coordinates.latitude - newLocation.coords.latitude) -
            Math.abs(b.coordinates.longitude - newLocation.coords.longitude))
        setLocations(locations);
    }

    React.useEffect(() => {(async () => {
        const locations = JSON.parse(localStorage.getItem('@locations') || "[]");
        if (locations.length < 1 || !navigator.geolocation) {
            setLocations(locations);
            setLoading(false);
            return;
        }

        navigator.geolocation.getCurrentPosition((location) => {
            sortLocations(locations, location);
            setLoading(false);
        }, (err) => {
            console.error(err);
            setLocations(locations);
            setLoading(false);
        });
    })()}, []);

    return (
        <div className={"container"}>
            {
                loading ? <CircularProgress /> :
                    <Table className={"home-screen"}>
                        <TableHead>
                            <Timeline intervals={intervals} />
                        </TableHead>
                        <TableBody>
                            { locations.map((location: LocationData) => <Location key={location.id} location={location} intervals={intervals} />) }
                        </TableBody>
                    </Table>
            }
            <Link to={"location"}>
                <IconButton className={"add-location"} >
                    <Add style={{fontSize: 40, color: 'white'}}>+</Add>
                </IconButton>
            </Link>
        </div>
    );
}

export default HomeScreen;

