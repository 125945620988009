import {List, ListItem, Typography} from "@mui/material";
import React from "react";

interface Hour {
    background: string,
    border: string,
    index: string,
    FeelsLikeTemperature: number,
    Max10mWindGust: number,
    ProbOfPrecipitation: number,
    Sunlight: number,
    TideHeight: number,
    TideHeightPercentage?: number,
    TotalPrecipAmount: number,
    WindDirectionFrom10m: number,
}

function HourView(props: {hour: Hour}) {
    const hour = props.hour;
    return (
        <List key={hour.index} className={"location-hour"} style={{ border: `3px solid ${hour.border}`, backgroundColor: hour.background }}>
            <ListItem className={"location-data"} >
                <Typography>{hour.FeelsLikeTemperature.toFixed(0)}&#176;C</Typography>
            </ListItem>
            <ListItem className={"location-data"} style={{backgroundImage: `linear-gradient(to bottom, transparent ${100 - hour.ProbOfPrecipitation}%, aquamarine ${hour.ProbOfPrecipitation}%)`}} >
                <Typography>{hour.TotalPrecipAmount.toFixed(1)}mm</Typography>
            </ListItem>
            <ListItem className={"location-data"} >
                <Typography>{hour.Max10mWindGust.toFixed(0)}m/s</Typography>
                <Typography style={{ transform: `rotate(${hour.WindDirectionFrom10m}deg)`, fontSize: 20 }}>&darr;</Typography>
            </ListItem>
            {
                hour.TideHeight ?
                    <ListItem className={"location-data"} style={{backgroundImage: `linear-gradient(to bottom, transparent ${100 - hour.TideHeightPercentage!}%, deepskyblue ${hour.TideHeightPercentage}%)`}} >
                        <Typography>{hour.TideHeight.toFixed(1)}</Typography>
                    </ListItem> : null
            }
        </List>
    )
}

export {HourView};
export type {Hour};